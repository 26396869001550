.sign-up {

  &__forgot {
    font-size: font(b1);
    color: color(--secondary);
  }
  &__check {
    display: flex;
    justify-content: space-between;
    margin-bottom: 43px;
  }
  &__password {
    height: 95px;
  }
  &__btn {
    padding: 17px 71px;
    margin-bottom: 42px;
  }
  &__signup {
    font-weight: 400;
    font-size: font(b1);

    &__link {
      color: color(--secondary);
      margin-left: 3px;
    }

    @include respond-to(mobile) {
      font-size: font(b1);
      line-height: 24px;
    }
  }
}
