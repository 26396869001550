.landing-category-nav {
  margin-top: 18px;
  &__item {
    display: inline-block;
    margin-right: 25px;
    transition: all 0.3s ease;
    font-size: font(h6);
    color: color(--l-txt, 0.7);
    font-weight: 300;
    padding: 0 1px 5px 1px;
    border-bottom: 2px solid color(--secondary, 0);
    cursor: pointer;
    line-height: 25px;

    &.active {
      color: color(--l-txt);
      text-shadow: 0 0 1px color(--l-txt), 0 0 1px color(--l-txt);
      border-bottom: 2px solid color(--secondary);
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(51, 177, 255, 0) 26.67%,
          #33b1ff 100%
        );
        opacity: 0.3;
        top: 0;
        left: 0;
      }
    }
  }

  & :last-child{
    margin-right: 0;
  }
  @include respond-to(tablet) {
    &__item {
      font-size: font(b3);
      line-height: 20px;
      padding: 0 1px 3px 1px;
    }
  }
}
