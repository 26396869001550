@import 'variables';
@import 'mixins';
@import 'vendor';
@import 'components/verifyInput';
@import 'components/signInput';
@import 'components/signCheck';
@import 'components/landingBtn';
@import 'components/landingTopCard';
@import 'components/countDown';
@import 'components/cardCountDown';
@import 'components/themeBtn';
@import 'pages/landing/landing';
@import 'pages/landing/landingHeader';
@import 'pages/landing/landingHeaderMobile';
@import 'components/landingInput';
@import 'pages/landing/landingNav';
@import 'pages/landing/landingMain';
@import 'pages/landing/landingMainTitles';
@import 'pages/landing/landingMainBtns';
@import 'pages/landing/landingMainStatus';
@import 'components/twinSpan';
@import 'components/landingCard';
@import 'components/auctionCardTime';
@import 'pages/landing/landingCardHeader';
@import 'pages/landing/landingCardProfile';
@import 'pages/landing/landingCardFooter';
@import 'pages/landing/landingCardPrice';
@import 'pages/landing/landingDrops';
@import 'pages/landing/landingDropsHeader';
@import 'pages/landing/landingDropsMain';
@import 'pages/landing/landingDropsInfo';
@import 'pages/landing/landingTop';
@import 'pages/landing/landingTopHeader';
@import 'components/landingDropDown';
@import 'pages/landing/landingTopContain';
@import 'pages/landing/landingCategory';
@import 'components/landingSelect';
@import 'pages/landing/landingCategoryHeader';
@import 'pages/landing/landingCategoryNav';
@import 'pages/landing/landingCategoryContain';
@import 'pages/landing/landingFooter';
@import 'pages/landing/landingFooterLink';
@import 'components/signBtns';
@import 'pages/sign/signIn';
@import 'pages/sign/signUp';
@import 'pages/sign/forgotPassword';
@import 'pages/sign/resetPassword';
@import 'pages/sign/verifyEmail';
@import 'pages/sign/verifyAccount';
@import 'layouts/signLayout';
@import 'components/signSider';
@import 'components/signHeader';
@import 'layouts/mainLayout';
@import 'layouts/mainHeader';
@import 'layouts/mainFooter';
@import 'layouts/mainNav';
@import 'layouts/news';
@import 'components/btnEffect';
@import 'components/lineSeprate';
@import 'components/dropdownParent';
@import 'components/dropdownWallet';
@import 'components/headerItems';
@import 'components/headerSearch'; 
@import 'components/headerQuestion';
@import 'components/headerNotif';
@import 'components/headerProfile';
@import 'components/newsRow';
@import 'components/newsColumn';
@import 'pages/home/home';
@import 'pages/home/homeNewsHeader';
@import 'pages/home/homeTop';
@import 'pages/home/giveAway';



body{
    margin: 0px;
    padding: 0px;
    background: color(--bg);
    color: color(--txt);
    overflow-x: hidden;
    font-family: 'Nunito', sans-serif;
}


