.dropdown-wallet {
    display: flex;
    justify-content: space-between;
  &__left {
    display: flex;
    gap: 8px;
  }

  &__right {
    display: flex;
    align-items: flex-end;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & span {
      display: block;
    }
  }

  &__title {
    color: color(--txt, 0.8);
    font-weight: 300;
    font-size: font(b2);
    line-height: 16px;
  }

  &__eth , &__price{
    color: color(--txt);
    font-size: font(b1);
    font-weight: normal;
    line-height: 18px;
  }
}
