.landing-main-status{
    margin-top: 108px;
    & > span{
        margin-right: 25px;
    }
    @include respond-to(landscape){
        
        margin-top: 80px;
        display: flex;
        justify-content:space-between;
        & > span{
            margin-right: 0;
        }
    }
    @include respond-to(tablet){
        margin-top: 40px;
        width: 246px;
        margin-right: auto;
        margin-left: auto;
    }
}