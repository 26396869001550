.landing-top-header{
    display: flex;
    align-items: center;
    &__txt{
       font-size: font(h1);
       color:  color(--l-txt , 0.6);
       font-weight: 300;
       margin-right: 6px;

       @include respond-to(tablet) {
           font-size: font(h7);
       }
    }
}