.card-count-down{
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__part{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__count{
        font-size: font(h5-1);
        color: color(--main-txt , 1);
    }

    &__title{
        font-size: font(b1);
        color: color(--main-txt , 1);
        font-weight: 200;
        line-height: 12px;
    }

    @include respond-to(tablet){
        &__count{
            font-size: font(b1);
        }
    
        &__title{
            font-size: font(b4);
            font-weight: 200;
        } 
    }
}

.small .card-count-down{

    &__count{
        font-size: font(h7);
        color: color(--main-txt , 1);
    }

    &__title{
        font-size: font(b4);
    }


    @include respond-to(tablet){
        &__count{
            font-size: font(b3);
        }
    
        &__title{
            font-size: font(b5);
        } 
    }
}