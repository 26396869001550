.news-row {
  padding: 12px;
  @include w-h(100%, 172px);
  border-radius: 10px;
  display: flex;
  cursor: pointer;

  & span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info {
    width: calc(100% - 178px);
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__date {
    -webkit-line-clamp: 1;
    letter-spacing: 0.05em;
    line-height: 24px;
    font-weight: 300;
    font-size: font(b1);
    color: color(--fade);
  }

  &__title {
    -webkit-line-clamp: 2;
    font-weight: 500;
    font-size: font(h3);
    line-height: 34px;
  }

  &__writer {
    -webkit-line-clamp: 1;
    font-weight: 300;
    font-size: font(h6);
    line-height: 28px;
    padding-top: 10px;
  }

  &__img {
    @include w-h(148px, 100%);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    border-radius: 10px;
  }
}
