.landing-drops-main {
  display: flex;
  justify-content: space-between;
  position: relative;
  //   display: grid;
  //   grid-template-columns: 1fr 1.5fr;
  //   grid-column-gap: 0px;
  //   grid-row-gap: 0px;

  & > img {
    @include respond-to(tablet) {
      @include w-h(125px, 173px);
    }
  }

  @include respond-to(tablet) {
    justify-content: center;
    height: 405px;
  }
}
