* {
  box-sizing: border-box;
}

input {
  outline: none;
  border: none;
}

a{
    text-decoration: none;
}

// ::-webkit-scrollbar {
//   width: 0px;
// }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.landing-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .landing-container {
    width: 750px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .landing-container {
    width: 970px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .landing-container {
    width: 1272px;
    padding-right: 71px;
    padding-left: 15px;

  }
}

.main-container {
  width: 100%;
  padding: 0 32px;
  margin-right: auto;
  margin-left: auto;
}

.blur-bg{
  background: color(--blur-bg , 0.5);
  backdrop-filter: blur(10px);
}
