.landing-category-header{
    margin-top: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title{
        font-size: font(h1);
        @include respond-to(tablet){
            font-size: font(h7);
        }
    }

}