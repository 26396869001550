.landing-drops-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 635px;
  padding-top: 53px;
  @include respond-to(tablet) {
    padding-top: 0;
    width: 195px;
  }
  &__title {
    color: color(--l-txt);
    display: block;
    font-size: font(l1);
    font-weight: 300;
    & span {
      color: color(--secondary);
    }

    @include respond-to(tablet) {
      font-size: font(h5);
    }
  }
  &__description {
    display: block;
    text-align: justify;
    margin-top: 40px;
    font-weight: 200;
    color: color(--l-txt);
    font-size: font(h7);

    @include respond-to(tablet) {
      font-size: font(b3);
      margin-top: 16px;
    }
  }
  &__count {
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url("../../../images/png/Subtract.png");
    background-repeat: no-repeat;
    background-position: bottom;
    height: 221px;
    & > span {
      display: block;
      margin-left: 45px;
      font-size: font(h3);
      font-weight: 500;
    }

    @include respond-to(landscape) {
      background-size: contain;
      height: 178px;

      & > span {
        font-size: font(h5);
        margin-left: 38px;
      }
    }
    @include respond-to(tablet) {
      height: 121px;
      & > span {
        margin-left: 26px;
        font-size: font(b1);
      }
    }
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 30px;
    & > button {
      width: 49%;
      padding: 16px 0;
    }
    @include respond-to(tablet) {
      margin-bottom: 0;
      & > button {
        font-weight: 300;
        padding: 6px 0;
        font-size: font(b3);
        border-radius: 8px;
      }
    }
  }
  &__upcoming {
    & > svg {
      stroke: color(--l-txt);
      margin-left: 11px;
    }

    @include respond-to(tablet) {
      border: 1px solid color(--l-txt);
      & > svg {
        @include w-h(16px, 16px);
        margin-left: 8px;
      }
    }
  }
  &__remind {
    & > svg {
      fill: color(--landing);
    }
    @include respond-to(tablet) {
      & > svg {
        @include w-h(11px, 13px);
        margin-right: 6px;
        margin-bottom: 2px;
      }
    }
  }
  &__down {
    @include respond-to(tablet) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 335px;
    }
  }
}

[data-theme="light"] .landing-drops-info__count {
  background-image: url("../../../images/png/Subtract-light.png");
  box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.08);
}
