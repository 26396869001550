.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  background: color(--nav);
  @include w-h(100%, 114px);
  display: flex;
  align-items: center;
  z-index: 999;
}

.main-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &__links {
    display: flex;
    align-items: center;
    gap: 48px;
    width: 609px;
  }
  &__link {
    display: flex;
    align-items: center;
    height: 25px;
    background: rgba(51, 177, 255, 0);
    transition: background 0.5s linear;
    & > svg:not(:first-of-type) {
      display: none;
    }
    & span {
      overflow: hidden;
      display: inline-block;
      max-width: 0;
      transition: all 0.4s ease;
      color: color(--white);
      font-weight: 500;
      font-size: font(b1);
      line-height: 18px;
    }
    &.active {
      position: relative;
      gap: 6px;
      background: rgba(51, 177, 255, 0.21);
      border-radius: 40px;
      padding: 12px 15px;
      height: 48px;
      & > svg:not(:last-of-type) {
        display: none;
      }
      & > svg:not(:first-of-type) {
        display: inline-block;
      }
      & > span {
        transition: all 0.8s ease;
        max-width: 100px;
      }
      &::after {
        position: absolute;
        content: " ";
        @include w-h(12px , 12px);
        animation: fade-top 0.4s forwards 0.25s;
        background: color(--secondary);
        border-radius: 50%;
        box-shadow: 0px 0px 12px color(--secondary);
        left: 0;
        right: 0;
        bottom: -88px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__circle {
    position: absolute;
    top: -23px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    background: color(--white);
    box-shadow: 0px 0px 8px #ffffff;
    @include w-h(6px, 6px);
  }
}

.main-footer__link.last{
    width: 109px;
    justify-content:flex-end;
}

@keyframes fade-top {
    from {bottom: -88px;}
    50% {bottom: -28px;}
    to {bottom: -38px;;}
  }
