.landing-main-titles {
  max-width: 504px;
  & span {
    display: block;
  }
  &__header {
    font-size: font(h6);
    font-weight: 400;
    @include respond-to(landscape) {
      text-align: center;
    }
    @include respond-to(tablet) {
      font-size: font(b4);
    }
  }
  &__body {
    &.first{
      margin-top: 8px;
    }
    font-size: font(l2);
    font-weight: normal;
    line-height: 76px;
    font-weight: 400;
    @include respond-to(landscape) {
      text-align: center;
    }
    @include respond-to(tablet) {
      font-size: font(h3-1);
      line-height: 38px;
      &.first{
        margin-top: 19px;
      }
    }
  }
  &__footer {
    margin-top: 24px;
    color: color(--txt, 0.8);
    font-weight: 300;
    @include respond-to(landscape) {
      text-align: center;
    }
    @include respond-to(tablet) {
      font-size: font(b3);
    }
  }
}
