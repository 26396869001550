.landing-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 579px;
  &__link {
    transition: all 0.3s ease;
    color: color(--txt, 0.8);
    font-size: font(b3);
    font-weight: 200;

    &.active {
      color: color(--txt);
      text-shadow: 0 0 1px color(--l-txt);
    }
  }

  &__line {
    @include w-h(1px, 31px);
    background: color(--txt, 0.8);
    margin: 0 8px;
  }
}

.connect-wallet-link {
  color: color(--l-txt);
  &:hover {
    & button {
      color: color(--l-txt, 0.8);
      border-color: color(--l-txt, 0.8);
    }
  }
}
