.sign-header {
  &__back {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__txt {
      margin-left: 8px;
      font-size: font(b1);
    }
    &__icon {
      margin-bottom: 3px;
    }
  }

  &__title{
    font-size: font(h2);
    margin-bottom: 40px;
  }

  @include respond-to(mobile){
    &__back {
      margin-bottom: 48px;
  
      &__txt {
        line-height: 18px;
        margin-left: 8px;
        font-size: font(b1);
      }
      &__icon {
        @include w-h(15px, 15px);
        margin-bottom: 3px;
      }
    }
  
    &__title{
      font-size: font(h5-1);
    }
  }
}
