.btn-effect {
  position: absolute;
  opacity: 0;
  will-change: transform;
}

.art1 {
  bottom: -30px;
  left: 70px;
  animation: art1 0.4s forwards linear;
}
.art2 {
  right: -20px;
  top: -12px;
  animation: art2 0.4s forwards linear;
}
.art3 {
  left: 16px;
  top: 10px;
  animation: art3 0.5s forwards linear;
}

.art4 {
  right: 55px;
  top: -22px;
  animation: art4 0.5s forwards linear;
}

.game1 {
  bottom: -20px;
  right: 20px;
  animation: game1 0.3s forwards linear;
}

.game2 {
  top: -20px;
  right: -20px;
  animation: game2 0.3s forwards linear;
}

.game3 {
  top: -40px;
  right: 40px;
  animation: game3 0.3s forwards linear;
}

.game4 {
  top: 1px;
  left: 7px;
  animation: game4 0.3s forwards linear;
}

.gem1 {
  bottom: -12px;
  right: 38px;
  animation: gem1 0.4s forwards linear;
}

.gem2 {
  top: -34px;
  right: -12px;
  z-index: 20;
  animation: gem2 0.4s forwards linear 0.4s;
}

.gem3 {
  top: -20px;
  right: 6px;
  animation: gem1 0.4s forwards linear;
}

.gem4 {
  bottom: 0px;
  left: 15px;
  z-index: 20;
  animation: gem4 0.3s forwards linear 0.3s;
}

.gem5 {
  bottom: -1px;
  left: -3px;
  animation: gem5 0.3s forwards linear;
}

@keyframes art1 {
  from {
    opacity: 0;
    transform: translateX(50px) translateY(60px) rotateZ(100deg) scale(0);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateX(0px) translateY(0px) rotateZ(0deg) scale(1);
  }
}

@keyframes art2 {
  from {
    opacity: 0;
    transform: translateX(100px) translateY(-70px) rotateZ(130deg) scale(1.5);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    right: -20px;
    top: -12px;
    transform: rotateZ(0deg) translateX(0) translateY(0) scale(1);
  }
}

@keyframes art3 {
  from {
    opacity: 0;
    transform: translateX(-80px) translateY(-80px) rotateZ(180deg) scale(3);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg) translateX(0) translateY(0) scale(1);
  }
}

@keyframes art4 {
  from {
    opacity: 0;
    transform: translateX(-40px) translateY(-80px)  scale(0);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateX(0) translateY(0) scale(1);
  }
}

@keyframes game1 {
  from {
    opacity: 0;
    transform: translateX(-50px) translateY(-80px) rotateZ(100deg);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateX(0px) translateY(0px) rotateZ(0deg);
  }
}

@keyframes game2 {
  from {
    opacity: 0;
    transform: translateX(70px) translateY(60px) rotateZ(100deg);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateX(0px) translateY(0px) rotateZ(0deg);
  }
}

@keyframes game3 {
  from {
    opacity: 0;
    transform: translateX(20px) translateY(60px) rotateZ(100deg);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateX(0px) translateY(0px) rotateZ(0deg);
  }
}

@keyframes game4 {
  from {
    opacity: 0;
    transform: translateX(100px) translateY(10px) rotateZ(100deg);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateX(0px) translateY(0px) rotateZ(0deg);
  }
}

@keyframes gem1 {
  from {
    opacity: 0;
    transform: scale(2) rotateZ(60deg);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: scale(1) rotateZ(0deg);
  }
}

@keyframes gem2 {
  from {
    opacity: 0;
    transform: rotateZ(30deg);
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

@keyframes gem3 {
  from {
    opacity: 0;
    transform: scale(0.2) rotateZ(30deg);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: scale(1) rotateZ(0deg);
  }
}

@keyframes gem4 {
  from {
    opacity: 0;
    transform: rotateZ(20deg) translateY(-40px);
  }
  30% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

@keyframes gem5 {
  from {
    opacity: 0;
    transform: scale(0.2) rotateZ(-10deg) translateX(-60px);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: scale(1) rotateZ(0deg) translateX(0px);
  }
}
