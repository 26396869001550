.dropdown-parent{
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  box-shadow: -10px 10px 50px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  background: rgba(17, 3, 30, 0.7);
  border: 2px solid color(--txt, 0.05);
}
