.news {
  margin-top: 40px;
  display: flex;
  gap: 56px;
}

.news-column-parent {
  width: 66%;
}

.news-row-parent {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:first-of-type{
    background: color(--news-bg);
  }

  &__title {
    font-size: font(h2);
    color: color(--txt);
    font-weight: 500;
    line-height: 37px;
  }
}
