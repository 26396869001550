.landing-main {
  padding-top: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to(landscape) {
    flex-direction: column;
    padding-top: 38px;
  }
}
.landing-main-right {
  position: relative;
  &__title {
    display: block;
    font-size: font(h7);
    color: color(--l-txt);
    margin-bottom: 24px;
    margin-top: 48px;
  }
}

.landing-card-effects{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.landing-card-effects:nth-of-type(1){
  transition: all linear 0.65s;
}
.landing-card-effects:nth-of-type(2){
  transition: all linear 0.55s;
}
.landing-card-effects:nth-of-type(3){
  transition: all linear 0.45s;
}
.landing-card-effects:nth-of-type(4){
  transition: all linear 0.35s;
}
.landing-card-effects:nth-of-type(5){
  transition: all linear 0.25s;
}
