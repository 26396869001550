.news-column {
  &__img {
    @include w-h(100%, 407px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }

  &__center {
    display: flex;
    align-items: baseline;
    margin-top: 24px;
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: font(h1);
    color: color(--txt);
    line-height: 51px;
    font-weight: 500;
    letter-spacing: 0.02em;
    width: 70%;
    height: 102px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__date {
    display: block;
    width: 30%;
    letter-spacing: 0.05em;
    font-weight: 300;
    font-size: font(h6);
    color: color(--fade);
    line-height: 24px;
    text-align: right;
  }

  &__bottom {
    color: color(--txt, 0.8);
    font-size: font(h5);
    display: flex;
    align-items: flex-end;
    justify-content:space-between;
  }

  &__writer {
    color: inherit;
    font-size: inherit;
  }

  &__btn {
    font-size: inherit;
    line-height: 28px;
    padding: 18px 70px;
    color: inherit;
    & svg {
      stroke: color(--txt);
      margin-left: 12px;
      @include w-h(24px, 24px);
    }
  }
}
