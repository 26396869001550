.bg-line-up{
    position:absolute;
    width:100%;
    left: 0;
    z-index: -1;
}

.bg-line-down{
    position: absolute;
    width:100%;
    left: 0;
    top: calc(125% + 278px);
    z-index: -1;
    transform: scaleX(-1);
}
