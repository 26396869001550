.landing-footer-link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 122px;

  &__title {
    font-size: font(h5);
    color: color(--l-txt, 0.9);
    font-weight: 300;
  }
  & a {
    font-size: font(h7);
    font-weight: 200;
    color: color(--l-txt, 0.6);

    &:hover {
      color: color(--l-txt, 0.8);
    }
  }

  @include respond-to(landscape) {
    margin-right: 75px;
  }
  @include respond-to(tablet) {
    margin-right: 90px;

    &__title {
      font-size: font(b2);
      margin-bottom: 8px;
    }

    & a {
      font-size: font(b3);
      margin-bottom: 8px;
    }
  }
  @include respond-to(mobile) {
    margin-right: 0;
  }
}
