.home-news-header {
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: color(--txt);

  &__title {
    font-size: font(h2);
    line-height: 37px;
    font-weight: 500;
  }
  &__link {
    display: flex;
    align-items: center;
    font-size: font(h6);
    color: inherit;
    line-height: 23px;

    &:hover {
        color: color(--secondary);
        & svg{
            stroke: color(--secondary);
        }
    }
  }

  &__icon {
    stroke: color(--txt);
    transition: all 0.2s linear;
  }
}
