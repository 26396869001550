.sign-sider {
  background-image: url("../../images/png/sign-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: calc(35% + 10px);
  height: 100vh;
  z-index: -1;
  position: fixed;
  left: 0;

  &__content {
    padding: 72px 32px 44px 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__info {
    font-size: font(b2);
    color: color(--white);
  }

  @include respond-to(tablet){
    display: none;
  }
}
