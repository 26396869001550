.sign-btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  & button {
    border-radius: 10px;
  }

  &__metamask {
    padding: 15px 66px;
    color: color(--l-txt);
    font-size: font(h5);
    line-height: 28px;
    & img {
      width: 32px;
      height: 33px;
      margin-right: 4px;
      margin-bottom: 1px;
    }
  }
  &__icon {
    padding: 17px;
    background: color(--l-txt);
  }

  @include respond-to(mobile) {
    margin-bottom: 24px;
    gap: 8px;
    &__icon {
      padding: 13px 13px;
      background: color(--l-txt);

      &:nth-child(1) {
        margin: 20px 5px;
      }
    }
    &__metamask {
      padding: 13px 0;
      flex-grow: 1;
      font-size: font(h7);
      line-height: 21px;
      & img {
        width: 26px;
        height: 26px;
        margin-right: 4px;
        margin-bottom: 1px;
      }
    }
  }
}

.sign-or {
  font-size: font(h6);
  color: color(--l-txt, 0.6);
  text-align: center;
  margin-bottom: 32px;
}
