.btn {
  border-radius: 12px;
  border: none;
  @extend %center;
  cursor: pointer;

  &__connect {
    padding: 14px 15px;
    font-size: font(b3);
    font-weight: 700;
    transition: all 0.3s ease;
  }

  &__border {
    border: 2px solid color(--txt);
    background: inherit;
    color: inherit;
  }
  &__solid {
    color: color(--bg);
    background: color(--secondary);
  }
  &__sign{
    color: color(--landing);
    background: color(--l-txt);
    font-size: font(h4);
    line-height: 30px;
    border-radius:10px;
    
    @include respond-to(mobile) {
      width: 100%;
      padding: 13px 0px !important;
      line-height: 21px;
      font-size: font(h7);
      margin-bottom: 40px !important;
    }
  }
  &__explore {
    padding: 14px 23px;
    font-size: font(h7);
    line-height: 25px;
    font-weight: 300;
    & svg {
      margin-left: 8px;
      stroke: color(--bg);
      @include respond-to(tablet) {
        @include w-h(18px, 18px);
      }
    }
    @include respond-to(tablet) {
      padding: 7px 17px;
      font-size: font(b2);
    }
  }
  &__create {
    padding: 14px 24px;
    font-size: font(h7);
    line-height: 25px;
    font-weight: 300;
    @include respond-to(tablet) {
      padding: 7px 17px;
      font-size: font(b2);
    }
  }
}
