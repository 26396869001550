.verify-code-input {
  margin-bottom: 40px;
  & div {
    width: 464px;
  }
  & input {
    caret-color: color(--l-txt, 0.7) !important;
    color: color(--l-txt, 0.7);
    transition: all 0.2s linear;
    background: color(--l-txt, 0.02);
    border: 1px solid color(--l-txt, 0.2) !important;
    border-radius: 10px;
    width: 64px !important;
    height: 64px !important;
    margin-right: 16px;
    &:last-child {
      margin-right: 0px;
    }
    &:focus {
      color: color(--l-txt, 0.9);
      border: 1px solid color(--l-txt, 0.6) !important;
    }
  }

  @include respond-to(mobile) {
    width: 100% !important;
    & div {
      width: 100%;
      display: flex;
      gap: 8px;
    }
    & input {
      display: block;
      margin-right: 0;
      width: 17% !important;
      height: calc(((100vw - 70px) / 6 )) !important;
    }
  }
}
