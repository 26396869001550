.count-down{
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    &__part{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__count{
        font-size: font(l2);
        color: color(--l-txt , 0.9);
    }

    &__title{
        font-size: font(h5);
        color: color(--l-txt , 0.7);
        font-weight: 300;
    }

    @include respond-to(landscape){
        &__count{
            font-size: font(l1);
        }
    
        &__title{
            font-size: font(h7);
            font-weight: 200;
        } 
    }

    @include respond-to(tablet){
        padding: 0 30px;
        &__count{
            font-size: font(h4);
        }
    
        &__title{
            font-size: font(b3);
            font-weight: 200;
        } 
    }
}