.landing-card-footer{
    @include w-h(100% , 96px);
    border-radius: 24px;
    padding: 16px 30px;
    display: flex;

    @include respond-to(tablet){
        @include w-h(100% , 64px);
        border-radius: 16px;
        padding: 8px 18px;
    }

    &__divider{
        @include w-h(1px , 100%);
        background: color(--main-txt , 0.8);
    }

    .small &{
        @include w-h(100% , 80px); 
        padding: 8px 16px;
        border-radius:12px;

        @include respond-to(tablet){
            @include w-h(100% , 53px);
            border-radius:8px;
            padding: 8px; 
        }
    }

}