.header-profile {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__img {
    border-radius: 8px;
    @include w-h(32px, 32px);
  }

  &__txt {
    display: inline-block;
    margin-top: 4px;
    margin-left: 8px;
    font-size: font(b2);
    font-weight: 500;
    line-height: 16px;
    color: color(--txt);
  }

  &__icon {
    margin-left: 8px;
    transition: all 0.3s linear;
  }

  &__link {
    margin-left: 8px;
    font-size: font(b2);
    font-weight: 500;
    line-height: 16px;
    color: color(--txt);
  }
}

.dropdown-profile {
  width: 231px;

  &__signout,
  &__dark {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link,
  &__signout,
  &__dark {
    color: color(--txt);
    font-size: font(b1);
    line-height: 18px;
    font-weight: normal;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__dark {
    & .ant-switch-handle{
      width: 10px;
      height: 10px;
      left: 2px;
      top: 3px;
      &::before{
        border-radius: 4px;
        background: color(--bg, 0.8);
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
      }
    }

    & .ant-switch-checked .ant-switch-handle {
      left: 9px;
  }

    & .ant-switch{
      min-width: 20px;
      width: 20px;
      height: 16px;
      background: color(--txt , 0.4);
      border-radius: 4px;
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.3);
    }

    & .ant-switch-checked {
      background: color(--secondary);
    }
      }
}
