.landing-main-btns {
  display: flex;
  margin-top: 34px;
  & .btn__solid {
    margin-right: 16px;
  }
  @include respond-to(landscape) {
    justify-content: center;
    margin-top: 24px;
  }
}
