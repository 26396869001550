.home-top {
  &__header {
    margin-top: 74px;
    display: flex;
    align-items: center;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 420px);
    justify-content: space-between;
    grid-gap: 20px;
  }
}

.landing-top-card.home {
  margin-top: 10px;
  @include w-h(420px, 185px);
  .landing-top-card {
    &__count {
      top: 33px;
    }
    &__img {
      @include w-h(112px, 112px);
    }
    &__info {
      @include w-h(358px, 104px);
      padding: 16px 20px 16px 76px;
      margin-bottom: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      background: radial-gradient(
        90% 90% at 50% 50%,
        rgba(63, 70, 223, 0.105) 30.21%,
        rgba(255, 255, 255, 0.126) 91.67%
      );

      & div {
        align-items: center;
      }
    }
    &__name {
      font-size: font(h5);
    }
    &__price {
      color: color(--txt);
      font-size: font(h6);
      font-weight: 500;
      & span {
        font-weight: 500;
      }
    }
    &__id {
      font-size: font(b1);
      font-weight: normal;
      color: color(--txt, 0.8);
    }

    &__convert {
      font-size: font(b1);
      font-weight: normal;
      color: color(--txt, 0.6);
    }
  }
}

.landing-top-card.home.first {
  .landing-top-card__count {
    top: 3px;
  }
  .landing-top-card__info {
    background: radial-gradient(
      90% 90% at 50% 50%,
      rgba(72, 55, 196, 0.3) 27.6%,
      rgba(255, 255, 255, 0) 91.67%
    );
  }
}
