.landing-drops{
    padding-top: 171px;

    &__divider{
        height:1px;
        background: color(--l-txt , 0.3);
        margin-bottom: 27px;
    }

    @include respond-to(landscape){
        padding-top: 100px;
    }
    @include respond-to(tablet){
        padding-top: 48px;
    }
}