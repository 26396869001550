.landing-search {
  @include w-h(288px, 48px);
  padding: 0 16px;
  display: flex;
  align-items: center;
  border: 1px solid color(--txt, 0.6);
  border-radius: 12px;
  background: color(--bg, 1);
  transition: all 0.3s ease;
  cursor: text;

  &__icon {
    margin-right: 12px;
    transition: inherit;
    stroke: color(--txt);
    stroke-opacity: 0.6;
    cursor: pointer;
  }

  &__input {
    flex-grow: 1;
    background: none;
    color: color(--txt, 0.8);
    transition: inherit;



  }
  &__input::placeholder {
    font-size: font(b3);
    color: color(--txt, 0.6);
    transition: inherit;
  }

  &.active {
    border: 1px solid color(--txt, 0.8);
  }

  .active & {
    &__icon {
      stroke-opacity: 0.8;
    }

    &__input::placeholder {
      font-size: font(b3);
      color: color(--txt, 0.8);
    }
  }
}

.landing-header-search{
  &__desk{
      @include respond-to(landscape){
          display: none;
      }
  }
}