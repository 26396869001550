.verify-account {

    &__btn{
      padding: 17px 63px;
      margin-bottom: 24px;
    }

    & .sign-header__title{
        margin-bottom: 32px;
    }
    &__info{
        display: block;
        font-size: font(h6);
        color: color(--l-txt , 0.6);
        font-weight: 200;
        line-height: 23px;
        margin-bottom: 41px;
    }

    @include respond-to(mobile){
      & .sign-header__title{
        margin-bottom: 20px;
    }
    }
  }