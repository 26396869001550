.nav-parent {
  margin-top: 77px;
}

.main-nav {
  @include w-h(100%, 72px);
  background: color(--nav);
  padding: 16px 16px;
  display: flex;
  align-items: center;
  border-radius: 10px;

  &__items {
    height: 100%;
    display: flex;
    gap: 16px;
  }

  &__item {
    cursor: pointer;
    position: relative;
    @include w-h(163px, 100%);
    background: linear-gradient(
      93.08deg,
      rgba(255, 255, 255, 0.2) 3.82%,
      rgba(255, 255, 255, 0.2) 100%
    );
    border: none;
    border-radius: 10px;
    font-size: font(h6);
    line-height: 23px;
    font-weight: 300;
    transition: all 0s ease;

    & span {
      position: relative;
      z-index: 10;
    }

    &.art,
    &.gaming,
    &.collectible {
      transition: all 0.4s ease;
      font-size: font(h3);
      @include w-h(200px, 72px);
      transform: translateY(-32px);
      &::after {
        content: "";
        @include w-h(100%, 100%);
        border-radius: 10px;
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        animation: fade-in 1s forwards;
      }
    }

    &.art {
      &::after {
        background: linear-gradient(93.08deg, #4837c4 3.82%, #33b1ff 100%);
      }
    }
    &.gaming {
      &::after {
        background: radial-gradient(
          165.63% 2120.83% at -15.63% -15.62%,
          #d360c8 0%,
          #35aafc 100%
        );
      }
    }
    &.collectible {
      &::after {
        background: linear-gradient(94.6deg, #2DA687 0%, #33B1FF 140.45%);
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
