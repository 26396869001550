.landing-top-card {
  @include w-h(341px, 143px);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  margin-top: 32px;
  &__img {
    cursor: pointer;
    @include w-h(86px, 86px);
    border-radius: 50%;
    position: absolute;
    left: 15px;
    z-index: 1;
  }
  &__info {
    cursor: pointer;
    @include w-h(271px, 82px);
    margin-bottom: 2px;
    padding: 16px 16px 16px 42.5px;
    background: linear-gradient(
      265.3deg,
      rgba(146, 146, 146, 0) -3.76%,
      rgba(146, 146, 146, 0.7) 113.56%
    );
    border-radius: 24px;
    position: relative;
    & ::after {
      content: "";
      position: absolute;
      border-radius: 24px;
      z-index: -1;
      @include w-h(100%, 100%);
      background: color(--landing);
      top: 0;
      left: 0;
    }
    & div {
      display: flex;
      justify-content: space-between;
    }
  }
  &__count {
    position: absolute;
    font-size: font(l1-1);
    color: color(--l-txt);
    left: 0;
    top: 24px;
  }
  &__name {
    color: color(--l-txt);
    font-size: font(h6);
    font-weight: 600;
  }
  &__price {
    color: color(--l-txt, 0.8);
    font-size: font(b1);
    font-weight: 400;
    & > span {
      font-weight: 200;
    }
  }
  &__id {
    font-size: font(b2);
    font-weight: 300;
    color: color(--l-txt, 0.8);
  }
  &__convert {
    font-size: font(b2);
    font-weight: 200;
    color: color(--l-txt, 0.6);
  }

  // MOBILE RESPONSIVE START

  @include respond-to(tablet) {
    @include w-h(265px, 82px);
    margin-top: 0;
    &__img {
      @include w-h(56px, 56px);
      position: absolute;
      left: 4px;
    }
    &__info {
      cursor: pointer;
      @include w-h(234px, 56px);
      margin-bottom: 0;
      padding: 8px 14px 8px 40px;
      border-radius: 16px;
      position: relative;
      & ::after {
        content: "";
        position: absolute;
        border-radius: 24px;
        z-index: -1;
        @include w-h(100%, 100%);
        background: color(--landing);
        top: 0;
        left: 0;
      }
      & div {
        align-items: center;
      }
    }
    &__count {
      font-size: font(h3-1);
      left: 0;
      top: 0;
      & > svg{
        @include w-h(24px , 38px);
        padding-right: 4px;
      }
    }
    &__name {
      color: color(--l-txt);
      font-size: font(b1);
      font-weight: 400;
      line-height: 22px;
    }
    &__price {
      font-size: font(b3);
      font-weight: 400;
      & > span {
        margin-left: 2px;
      }
    }
    &__id {
      font-size: font(b3);
      font-weight: 200;
    }
    &__convert {
      font-size: font(b4);
    }
  }

  // MOBILE RESPONSIVE END
}

.landing-top-card.first .landing-top-card__count {
  left: 0;
  top: 0;
}

.landing-top-card.first .landing-top-card__info {
  background: linear-gradient(
    265.3deg,
    rgba(51, 177, 255, 0) -3.76%,
    rgba(51, 177, 255, 0.7) 113.56%
  );
}

[data-theme="light"] .landing-top-card.first .landing-top-card__info {
  background: linear-gradient(
    265.3deg,
    rgba(36, 162, 240, 0) -3.76%,
    rgba(36, 162, 240, 0.2) 113.56%
  );
}

[data-theme="light"] .landing-top-card .landing-top-card__info {
  background: linear-gradient(
    265.3deg,
    rgba(221, 221, 221, 0) -3.76%,
    rgba(221, 221, 221, 0.7) 111.52%
  );
}
