.landing-select {
  position: relative;
  width: 193px;
  height: 48px;

  &__icon {
    right: 27px;
    opacity: 1;
    position: absolute;
    top: 12px;
    .active & {
      & svg {
        transform: rotate(180deg);
      }
    }
    & svg {
      transition: all 0.3s ease;
      stroke: color(--l-txt);
      @include w-h(14px, 22px);

      & path {
        stroke-width: 3px;
      }
    }
  }
  &__items {
    position: absolute;
    background: color(--l-txt);
    width: 100%;
    z-index: 3;
    border-radius: 12px;
    display: flex;
    flex-flow: column;
    max-height: 48px;
    overflow: hidden;
    transition: all 0.19s ease;
    z-index: 1;
    cursor: pointer;

    &::before {
      border-radius: 10px;
      content: "";
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: color(--landing);
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: -1;
    }
    &::after {
      border-radius: 12px;
      content: "";
      width: calc(100%);
      height: 100%;
      background: linear-gradient(359.07deg, #ffffff00 0.76%, #ffffff 99.2%);
      position: absolute;
      z-index: -2;
    }
    .active &::after {
      background: linear-gradient(
        359.07deg,
        rgba(255, 255, 255, 0.05) 0.76%,
        #ffffff 99.2%
      );
    }
    .active & {
      background: color(--landing, 0.2);
      max-height: 1000px;
      transition: all 0.6s ease;
      border-radius: 12px;
    }
  }

  &__item {
    border-radius: 12px;
    color: color(--l-txt);
    font-size: font(h7);
    cursor: pointer;
    line-height: 20px;
    order: 3;
    padding: 13px 23px;
  }

  @include respond-to(tablet){
    width: 85px;
    height: 24px;

    &__items {
      border-radius: 6px;
      max-height: 24px;

      &::before {
        border-radius: 6px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        left: 1px;
        top: 1px;
      }
      &::after {
        border-radius: 6px;
      }
      .active & {
        border-radius: 6px;
      }
    }

    &__item {
      border-radius: 6px;
      font-size: font(b5);
      padding: 3px 15px;
    }

    &__icon {
      right: 15px;
      top: 5px;
      .active & {
        & svg {
          transform: rotate(180deg);
        }
      }
      & svg {
        @include w-h(8px, 12px);
  
        & path {
          stroke-width: 2px;
        }
      }
    }

  }
}

[data-theme="light"] .landing-select {
  &__items {
    &::after {
      border-radius: 12px;
      content: "";
      width: calc(100%);
      height: 100%;
      background: color(--l-txt);
      position: absolute;
      z-index: -2;
    }
    .active &::after {
      background: color(--l-txt);
    }

  }
  @include respond-to(tablet){

    &__items {
      border-radius: 7px;
      &::before {
        border-radius: 5px;
      }
      &::after {
        border-radius: 6px;
      }
      .active & {
        border-radius: 2px;
      }
    }

    &__item {
      border-radius: 4px;
      font-size: font(b5);
      padding: 3px 15px;
    }
  }
}
