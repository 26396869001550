.twin-span {
  display: inline-flex;
  align-items: center;
  font-size: font(h2);
  font-weight: 600;
  &__title {
    font-size: font(h6);
    color: color(--txt, 0.7);
    font-weight: 300;
    margin-left: 14px;
    margin-top: 4px;
    @include respond-to(landscape){
      margin-left: 0;
      margin-top: 0;
    }
    @include respond-to(tablet){
      font-size: font(b2);
    }
  }
  @include respond-to(landscape){
    flex-direction: column;
    justify-content: center;
  }
  @include respond-to(tablet){
    font-size: font(h7);
  }
}
