.landing-header{
    padding-top: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .logo-img {
        margin-right: 21px;
      }
}
