.auction-card-time{
    flex: 1;
    padding-left: 45px;

    &__title{
        display: block;
        margin-top: -2px;
        font-weight: 200;
        font-size: font(b3);
    }

    @include respond-to(tablet){
        padding-left: 24px;
        padding-right: 5px;

        &__title{
            font-size: font(b5);
            font-weight: 300;
        }
    }
}

.small .auction-card-time{
    flex: 1;
    padding-left: 15px;
    padding-right: 8px;
    padding-top: 8px;

    &__title{
        display: block;
        font-weight: 200;
        font-size: font(b4);
    }

    @include respond-to(tablet){
        padding-top: 0;

        &__title{
            font-size: font(b5);
        }
    }
}

