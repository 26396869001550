.landing-card-profile {
  display: inline-flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 10px;
  margin-top: 16px;

  @include respond-to(tablet) {
    padding: 4px;
    margin-top: 8px;
  }

  &__id {
    font-size: font(b3);
    margin-left: 8px;
    font-weight: 200;
    display: inline-block;

    @include respond-to(tablet) {
      margin-left: 4px;
      font-size: font(b4);
      font-weight: 300;
    }
  }
  &__img {
    @include w-h(24px, 24px);
    border-radius: 6px;

    @include respond-to(tablet) {
      padding: 4px;
      @include w-h(20px, 20px);
    }
  }

  .small &{
    padding: 4px;
    margin-top: 14px;
    border-radius: 6px;
    &__id {
      font-size: font(b4);
      font-weight: 300;
    }
    &__img {
      @include w-h(20px, 20px);
    }

    @include respond-to(tablet) {
      margin-top: 8px;
      &__id {
        font-size: font(b5);
        margin-left: 6px;
        margin-top: 2px;
      }
      &__img {
        @include w-h(17px, 17px);
      }
    }
  }
}
