.sign-layout {
  background: color(--landing);
  width: 65%;
  border-radius: 12px 0 0 12px;
  position: relative;
  min-height: 100vh;

  @include respond-to(tablet){
    width: 100%;
  }
}

.sign-layout-shape {
  height: 100vh;
  width: 1px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 2;

  &__img {
    max-width: 496px;
    max-height: 496px;
    position: absolute;
    left: -452px;
    @media (max-width: 1320px) {
      max-width: 410px;
      max-height: 410px;
      left: -373px;
    }
    @media (max-width: 1125px) {
      max-width: 360px;
      max-height: 360px;
      left: -327px;
    }
  }

  @include respond-to(tablet){
    display: none;
  }
}

.sign-layout-content {
  &__container {
    width: 464px;
    margin: 0 auto;
    padding: 141px 0;

    @media (min-width: 1441px) {
      margin-left: 235px;
    }

    @include respond-to(tablet){
      padding: 50px 15px;
      padding-top: 0;
    }
    @include respond-to(mobile){
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.sign-mobile-logo{
  width:100%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 58px;
  & svg{
    @include w-h(105px , 32px)
  }
}

.sign-layout-content{
  display: flex;
  justify-content: flex-end;

  @include respond-to(tablet){
    flex-wrap: wrap;
  }
}