.landing-footer {
  margin-top: 54px;
  height: 378px;
  padding: 96px 0 72px 0;
  background: color(--l-txt, 0.05);

  &__icon {
    @include w-h(185px, 58px);
  }

  @include respond-to(tablet) {
    margin-top: 48px;
    height: max-content;
    padding: 24px 0;

    & .logo-img-txt {
      @include w-h(77px, 24px);
      margin-bottom: 24px;
    }
  }
  & .divider {
    @include w-h(100%, 1px);
    background: color(--l-txt, 0.3);
  }
}

.landing-footer-column {
  display: flex;

  @include respond-to(tablet) {
    flex-direction: column;
  }
}

.landing-footer-left {
  max-width: 408px;
  margin-right: 118px;

  @include respond-to(landscape) {
    margin-right: 70px;
  }
  @include respond-to(tablet) {
    margin-right: 0;
  }

  &__txt {
    font-size: font(h6);
    color: color(--l-txt, 0.8);
    font-weight: 200;
    display: block;
    margin-top: 22px;

    @include respond-to(tablet){
      font-size: font(b2);
      margin-top: 0px;
      max-width: 70%;
      color: color(--l-txt);
    }
  }
  &__input {
    @include w-h(279px, 50px);
    border-radius: 16px;
    & ::placeholder {
      font-size: 14px;
      color: color(--l-txt, 0.8);
    }
    & input:focus::placeholder {
      font-size: 14px;
      color: color(--l-txt, 0.9);
    }
  }
  &__btn {
    padding: 14px 20px;
    border-radius: 16px;
    font-size: font(b1);
    font-weight: 300;

    @include respond-to(tablet){
      font-size: font(b3);
      padding: 8px 12px;
      border-radius: 8px;
    }
  }
}

.landing-footer-form {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
}

.landing-footer-links {
  display: flex;
  @include respond-to(tablet) {
    margin: 0 16px;
  }
  @include respond-to(mobile){
    justify-content: space-between;
  }
}

.landing-mobile-subscribe{
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}