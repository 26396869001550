.header-notif {
  display: inline-flex;
  align-items: center;
  padding: 6px 8px;
  background: radial-gradient(
    circle,
    rgba(63, 70, 223, 0.700717787114846) 0%,
    rgba(63, 70, 223, 0.5690651260504201) 80%,
    rgba(63, 70, 223, 0.3337710084033614) 100%
  );
  border: 1px solid #3f46df;
  box-shadow: 0px 4px 31px rgba(63, 70, 223, 0.4),
    inset 4px 4px 4px rgba(0, 0, 0, 0.25),
    inset -3px -3px 6px rgba(0, 0, 0, 0.35);
  border-radius: 14px;
  margin-bottom: 5px;
  &__icon {
    margin-right: 4px;
    @include w-h(12.5px, 14px);
  }
  &__count {
    font-size: font(b3);
    line-height: 14px;
    font-weight: 500;
  }
}
