.give-away {
  margin-top: 72px;
  &__header {
    margin-bottom: 40px;
    font-weight: 500;
    color: color(--txt);
    line-height: 37px;
    font-size: font(h2);
  }
  &__content {
    @include w-h(100%, 587px);
    display: flex;
    gap: 50px;
    & > .landing-card {
      width: 35%;
    }
  }
}
