.landing-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  &__title {
    display: inline-block;
    padding: 8px 14px;
    border-radius: 10px;
    font-size: font(h6);
    font-weight: 300;

    @include respond-to(tablet) {
      padding: 6px 8px;
      font-size: font(b2);
    }
  }
  &__like {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 10px;

    @include respond-to(tablet) {
      padding: 6px 8px;
    }

    & svg {
      margin-left: 10px;
      fill: color(--main-txt);
      cursor: pointer;

      @include respond-to(tablet) {
        @include w-h(16px , 16px);
        margin-left: 8px;
      }
    }
  }
  &__count {
    display: inline-block;
    color: inherit;
    font-size: font(b1);
    font-weight: 300;

    @include respond-to(tablet) {
      font-size: font(b2);
    }
  }

  .small & {
    &__title {
      display: inline-block;
      padding: 2px 4px;
      font-size: font(b1);
    }
    &__like {
      display: flex;
      align-items: center;
      padding: 6px 8px;

      & svg {
        margin-left: 8px;
        @include w-h(14px, 14px);
      }
    }
    &__count {
      font-size: font(b3);
    }

    @include respond-to(tablet) {
      &__title {
        padding: 5px 8px;
        font-size: font(b4);
        line-height: 14px;
        border-radius: 6px;
      }
      &__like {
        padding: 5px 8px;
        border-radius: 6px;
  
        & svg {
          margin-left: 6px;
          @include w-h(12px, 12px);
        }
      }
      &__count {
        font-size: font(b4);
        line-height: 14px;
      }
    }
  }
}
