.drop-down-bg {
  border-radius: 12px;
  margin-top: 14px;
  transition: all .3s ease
  // padding: 1.3px;
  // box-shadow: -10px 10px 50px rgba(0, 0, 0, 0.3);
  // backdrop-filter: blur(20px);
  // background: linear-gradient(
  //   359.07deg,
  //   rgba(255, 255, 255, 0.05) 0.76%,
  //   #ffffff 99.2%
  // );
}

.drop-down {
  width: 239px;
  position: relative;
  border-radius: 12px;
  background: color(--landing);
  padding: 24px;
  &::after {
    content: "";
    border-radius: 12px;
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: linear-gradient(
      359.07deg,
      rgba(255, 255, 255, 0.05) 0.76%,
      #ffffff 99.2%
    );
    z-index: -1;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: font(h3);
    color: color(--l-txt);
    & svg {
      fill: color(--secondary);
    }
  }
  @include respond-to(tablet){
    width: 168px;
    padding: 18px;
    &__item {
      font-size: font(h7);
      & svg {
        @include w-h(26px , 26px)
      }
    }
  }
  &__title {
    color: color(--l-txt);
    display: inline-flex;
    align-items: center;
    font-size: font(h1);
    font-weight: 400;
    margin-right: 20px;
    & > span {
      position: relative;
      display: inline-block;
      padding-right: 5px;
      padding-left: 3px;
      margin-right: 11px;
      border-bottom: 2px solid color(--secondary);
      line-height: 42px;
      &::after {
        content: "";
        width: 100%;
        height: 30px;
        opacity: 0.2;
        background: linear-gradient(
          180deg,
          rgba(63, 70, 223, 0) 26.67%,
          color(--secondary) 100%
        );
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    & > svg {
      stroke: color(--l-txt);
      margin-bottom: 1px;
      transition: all 0.1s linear;
    }
    @include respond-to(tablet){
      margin-right: 16px;
      font-size: font(h7);
      & > span {
        line-height:25px;
        margin-right: 9px;
      }
      & > svg {
        @include w-h(9px , 12px);
        & path {
          stroke-width:3px;
        }
      }
    }
  }
}

.ant-dropdown-open svg {
  transform: rotate(180deg);
}

body .drop-down__title:active {
  color: color(--l-txt);
}
body .drop-down__title:hover {
  color: color(--l-txt);
}
.drop-down-bg a:active {
  color: color(--l-txt);
}
.drop-down-bg a:hover {
  color: color(--l-txt);
}

[data-theme="light"] .drop-down {
  background: rgba(#f2f2f2, 0.6);
  box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(36px);
}
