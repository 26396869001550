.landing-card-price {
  display: flex;
  flex-direction: column;
  @include w-h(41%, 100%);

  &__title {
    font-weight: 300;
    font-size: font(b3);
    @include respond-to(tablet){
      font-size: font(b5);
    }
  }

  &__price {
    display: inline-block;
    font-size: font(h5-1);
    font-weight: 600;
    margin-top: 4px;
    line-height: 30px;
    & span {
      display: inline-block;
      font-weight: 200;
      margin-left: 4px;
      @include respond-to(tablet){
        margin-left: 2px;
      }
    }
    @include respond-to(tablet){
      margin-top: 2px;
      font-size: font(b1);
      line-height: 24px;
    }
  }

  &__convert {
    display: inline-block;
    color: color(--main-txt, 0.6);
    font-weight: 300;
    font-size: font(b3);
    @include respond-to(tablet){
      font-size: font(b5);
    }
  }

  .small & {
    padding: 8px 0 2px 0;
    &__title {
      font-size: font(b4);
    }

    &__price {
      font-size: font(h7);
      margin-top: 4px;
      line-height: 20px; 
      & span {
        margin-left: 3px;
      }
    }
  
    &__convert {
      font-weight: 300;
      font-size: font(b4);
    }

    @include respond-to(tablet){
      padding: 0;
      &__title {
        font-size: font(b5);
      }
      &__price {
        font-size: font(b3);
        margin-top: 2px;
        line-height: 16px; 
        & span {
          margin-left: 3px;
        }
      }
      &__convert {
        font-weight: 300;
        font-size: font(b6);
      }
    }
  }
}

.landing-card-price.fixed{
    @include w-h(100%, 100%);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
