.landing-card {
  @include w-h(395px, 600px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 80px;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 16px;
  color: color(--main-txt);
  transition: all linear 0.15s;
  &.large::after {
    content: " ";
    // background-image: url("../../images/png/landing-card-effect.png");
    @include w-h(417px, 536px);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: -80px;
  }

  @include respond-to(landscape) {
    margin-right: 0px;
    &.large::after {
      background-image: url("../../images/png/mobile-card-after.png");
      background-size: cover;
      bottom: 0;
      left: 0;
    }
    &.large::before {
      content: " ";
      @include w-h(417px, 536px);
      background-image: url("../../images/png/mobile-card-after.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      z-index: -1;
      bottom: 0;
      right: 0;
    }
  }
  @include respond-to(tablet) {
    @include w-h(285px, 433px);
    &.large::after {
      @include w-h(285px, 433px);
      background-size: auto;
      bottom: -33px;
      left: -16px;
    }
    &.large::before {
      @include w-h(285px, 433px);
      background-size: auto;
      bottom: -33px;
      right: -16px;
    }
  }
}

[data-theme="light"] .landing-card.landing.large::after {
  // background-image: url("../../images/png/landing-card-effect-light.png");

  @include respond-to(landscape) {
    background-image: url("../../images/png/mobile-card-after.png");
  }
}

.landing-card.small {
  @include w-h(300px, 455px);
  margin-right: 0;
  border-radius: 16px;

  @include respond-to(tablet) {
    @include w-h(197.5px, 300px);
    border-radius: 12px;
    padding: 10px;
  }
}