.main-layout {
  height:100vh;
}

.test{
    width: 100%;
    background: rgba(0, 217, 255, 0.153);
    height: 300px;

    &:nth-child(odd){
        background: rgba(255, 16, 16, 0.098); 
    }
}