// functions START

  @function font($size) {
    $remValue: (map-get($fonts, $size) / 16) + rem;
    @return $remValue;
  }
  
  @function color($color , $opacity : 1) {
    @if $color == --primary{
      $result : var($color);
      @return $result;
    }
    @else{
      $result: rgba(var($color), $opacity);
      @return $result;
    }
  }

  @mixin w-h($width: 100%, $height: max-content) {
    width: $width;
    height: $height;
  }  

  @mixin respond-to($breakpoint){
    $media: map-get($breakpoints, $breakpoint);

    @media #{$media}{
      @content;
    }
  }

// functions END  

// planceholders START 

%center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

// planceholders END 
