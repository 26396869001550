.landing-drops-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 14px;
  &__title {
    font-size: font(h1);
    line-height: 49px;
    font-weight: 500;
    color: color(--l-txt);

    @include respond-to(tablet){
      font-size: font(h7);
      line-height: 24px;
    }
  }
  &__link {
    display: flex;
    font-weight: 300;
    align-items: center;
    font-size: font(h6);
    color: color(--l-txt);

    & svg{
        margin-left: 11px;
        stroke: color(--l-txt);
        transition: all 0.2s linear;

        @include respond-to(tablet){
          @include w-h(16px, 16px);
          margin-left: 8px;
        }
    }

    @include respond-to(tablet){
      font-size: font(b3);
    }

    &:hover svg {
      stroke: color(--secondary);
    }
  }

  @include respond-to(tablet){
    margin-bottom: 24px;
  }
}
