.landing-theme-btn{
    position: fixed;
    right: 45px;
    bottom: 45px;
    z-index: 99999;

    &__btn{
        @include w-h(45px , 45px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        animation: startBtn 0.8s forwards linear;
        border: none;
    }

    &__moon{
        background: color(--l-txt , 0.9);

        & svg{
            @include w-h(30px , 30px); 
            fill: color(--landing);
        }
    }

    &__sun{
        background: color(--l-txt , 0.9);

        & svg{
            @include w-h(30px , 30px); 
            fill: color(--landing);
        }
    }

}

@keyframes startBtn {
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
  }