body .sign-input .ant-input-affix-wrapper {
  background: color(--l-txt, 0.02);
  height: 64px;
  border-radius: 10px;
  border: 1px solid color(--l-txt, 0.2);
  padding-left: 20px;
  & input {
    background: none;
    color: color(--l-txt, 0.7);
    font-size: font(h6);

    &:focus {
      color: color(--l-txt, 0.9);
    }

    &::placeholder {
      color: color(--l-txt, 0.6);
      font-size: font(h6);
      font-weight: 200;
    }
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid color(--l-txt, 0.5);
}

.ant-input-affix-wrapper-focused {
  border: 1px solid color(--l-txt, 0.8) !important;
  box-shadow: none;
}

.sign-input {
  height: 104px;
  &__input {
    background: none;
    color: color(--l-txt, 0.7);
    font-size: font(h6);
    padding-left: 20px;
    background: color(--l-txt, 0.02);
    height: 64px;
    border-radius: 10px;
    border: 1px solid color(--l-txt, 0.2);

    &::placeholder {
      color: color(--l-txt, 0.6);
      font-size: font(h6);
      font-weight: 200;
    }
    &:hover {
      border: 1px solid color(--l-txt, 0.5);
    }
    &:focus {
      border: 1px solid color(--l-txt, 0.9);
      color: color(--l-txt, 0.9);
    }
  }
}

.sign-input__title {
  color: color(--l-txt);
  font-size: font(h5);
  margin-bottom: 16px;
}

@include respond-to(mobile) {
  .sign-input {
    height: 96px;
    &__input {
      font-size: font(b1);
      height: 56px;

      &::placeholder {
        font-size: font(b1);
        font-weight: 400;
      }
    }
  }
  .sign-input__title {
    color: color(--l-txt , 0.8);
    font-size: font(b1);
    padding-left: 12px;
    line-height: 18px;
  }

  body .sign-input .ant-input-affix-wrapper {
    height: 56px;
    & input {
      font-size: font(b1);

      &::placeholder {
        font-size: font(b1);
        font-weight: 400;
      }
    }
  }
}
